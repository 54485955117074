export type LocationLabel =
  | 'Australian Capital Territory'
  | 'New South Wales'
  | 'Northern Territory'
  | 'Queensland'
  | 'South Australia'
  | 'Victoria'
  | 'Tasmania'
  | 'Western Australia'
  | 'South Island'
  | 'North Island';

export type LocationValue =
  | 'Australian Capital Territory'
  | 'New South Wales'
  | 'Northern Territory'
  | 'Queensland'
  | 'South Australia'
  | 'Victoria'
  | 'Tasmania'
  | 'Western Australia'
  | 'NZ South Island'
  | 'NZ North Island';

export type LocationGroupLabel = 'All Locations' | 'All Australia' | 'All New Zealand';

export type Location = {
  label: LocationLabel;
  value: LocationValue;
};

export const ALL_LOCATIONS = 'All Locations';
export const ALL_AUSTRALIA = 'All Australia';
export const ALL_NEW_ZEALAND = 'All New Zealand';

export const LOCATIONS: Record<LocationGroupLabel, Location[]> = {
  [ALL_LOCATIONS]: [],
  [ALL_AUSTRALIA]: [
    {
      label: 'Australian Capital Territory',
      value: 'Australian Capital Territory',
    },
    {
      label: 'New South Wales',
      value: 'New South Wales',
    },
    {
      label: 'Northern Territory',
      value: 'Northern Territory',
    },
    {
      label: 'Queensland',
      value: 'Queensland',
    },
    {
      label: 'South Australia',
      value: 'South Australia',
    },
    {
      label: 'Victoria',
      value: 'Victoria',
    },
    {
      label: 'Tasmania',
      value: 'Tasmania',
    },
    {
      label: 'Western Australia',
      value: 'Western Australia',
    },
  ],
  [ALL_NEW_ZEALAND]: [
    {
      label: 'North Island',
      value: 'NZ North Island',
    },
    {
      label: 'South Island',
      value: 'NZ South Island',
    },
  ],
};

export const LOCATIONS_VALUE_TO_LABEL_MAP = new Map<LocationValue, LocationLabel>(
  Object.values(LOCATIONS)
    .flat()
    .map(i => [i.value, i.label]),
);

export const LOCATIONS_LABEL_TO_VALUE_MAP = new Map<LocationLabel, LocationValue>(
  Object.values(LOCATIONS)
    .flat()
    .map(i => [i.label, i.value]),
);
