import dayjs from 'dayjs';
// the customParseFormat is required for strict validation
import customParseFormat from 'dayjs/plugin/customParseFormat';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';

import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';

dayjs.extend(customParseFormat);
dayjs.extend(isSameOrBefore);

export const isBeforeNow = dateTime => {
  const now = dayjs(new Date());
  return dayjs(dateTime).add(1, 'day').isBefore(now);
};

export const saleforceDateFormat = (date: Date) => {
  return Intl.DateTimeFormat('en-NZ', { day: '2-digit', month: '2-digit', year: 'numeric' }).format(
    date,
  );
};

export const toIsoDateString = (date: string) => dayjs(date).toISOString();

export const getTomorrow = () => dayjs().add(1, 'day');

/**
 * Performs a strict date validation against a given format
 * Checks if date is actually a valid one and in the right format
 */
export const isValidDate = (date: string | null | undefined, format: string): boolean =>
  dayjs(date, format, true).isValid();

/**
 * Converts an ISO date to given format
 * ! Will try to convert even invalid dates using native 'Date' object rules
 * For example '2022-02-31' will be converted to '2022-03-03'
 */
export const parseAndFormatDate = (
  dateToParse: dayjs.ConfigType,
  format: string,
): string | null => {
  if (!dateToParse) {
    return null;
  }
  const dayjsDate = dayjs(dateToParse);
  return dayjsDate.isValid() ? dayjsDate.format(format) : null;
};

//Identify weekends
export const disableWeekends = (date: MaterialUiPickersDate) => {
  if (date && (date.getDay() == 0 || date.getDay() === 6)) {
    return true;
  }
  return false;
};

export const isSameOrBeforeCurrentDate = (date: Date | string) => {
  const now = dayjs(new Date());
  return dayjs(date).isSameOrBefore(now);
};

export const isWeekendDate = (date: Date | string) => {
  const day = dayjs(date).day();
  return day === 0 || day === 6;
};
