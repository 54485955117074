import {
  ALL_AUSTRALIA,
  ALL_LOCATIONS,
  ALL_NEW_ZEALAND,
  LOCATIONS,
  LOCATIONS_LABEL_TO_VALUE_MAP,
  LocationLabel,
} from '@/constants/jobLocations';

export const convertLocationDisplayListToLocationString = (
  locationDisplayValues: string[],
): string => {
  let locationValues = [...locationDisplayValues];

  // replace group names with values
  if (locationDisplayValues.indexOf(ALL_LOCATIONS) >= 0) {
    locationValues = [];
  } else {
    Object.keys(LOCATIONS).forEach(key => {
      if (key !== ALL_LOCATIONS && locationValues.indexOf(key) >= 0) {
        locationValues.splice(locationValues.indexOf(key), 1);
        const subItems = LOCATIONS[key].map(item => item.value);
        locationValues = [...locationValues, ...subItems];
      }
    });
  }

  // replace individual names with values
  const mappedLocationValues = locationValues.map(
    label => LOCATIONS_LABEL_TO_VALUE_MAP.get(label as LocationLabel) || label,
  );

  return mappedLocationValues.join(';');
};

const isAllSelected = (selectedLocations, groupName) => {
  let allSelected = true;
  for (const item of LOCATIONS[groupName]) {
    if (selectedLocations.indexOf(item.value) < 0) {
      allSelected = false;
      break;
    }
  }
  return allSelected;
};

export function convertLocationStringToLocationDisplayList(location?: string): string[] {
  // if 'All Locations' is selected
  if (!location) {
    return [];
  }

  const previousLocationValues = location.split(';');
  let displayValues = [...previousLocationValues];

  Object.keys(LOCATIONS).forEach(key => {
    if (key !== ALL_LOCATIONS) {
      const allSelected = isAllSelected(displayValues, key);
      // if all sub items are selected, then remove sub items and add the group name
      if (allSelected) {
        for (const item of LOCATIONS[key]) {
          displayValues.splice(displayValues.indexOf(item.value), 1);
        }
        displayValues = [...displayValues, key];
      }
      if (key === ALL_NEW_ZEALAND) {
        for (const item of LOCATIONS[key]) {
          if (displayValues.indexOf(item.value) >= 0) {
            displayValues.splice(displayValues.indexOf(item.value), 1, item.label);
          }
        }
      }
    }
  });
  return displayValues;
}

/**
 * Converts front end location data to back end location data
 */
export const convertLocationsToValues = (location?: string): string => {
  if (location) {
    const locationLabels = location.split(';');
    Object.keys(LOCATIONS).forEach(key => {
      if (key === ALL_NEW_ZEALAND) {
        for (const item of LOCATIONS[key]) {
          if (locationLabels.indexOf(item.label) >= 0) {
            locationLabels.splice(locationLabels.indexOf(item.label), 1, item.value);
          }
        }
      }
    });
    return locationLabels.join(';');
  }
  return '';
};

/**
 *  Generate location name from location string:
 */
export function generateLocationNameFromLocationString(location?: string): string {
  if (!location) {
    return '';
  }
  let displayName = convertLocationStringToLocationDisplayList(
    convertLocationsToValues(location),
  )[0];
  // remove 'All' prefixes for AU and NZ
  if (displayName === ALL_NEW_ZEALAND) {
    displayName = 'New Zealand';
  }
  if (displayName === ALL_AUSTRALIA) {
    displayName = 'Australia';
  }
  return displayName;
}
